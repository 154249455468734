import { PrinterOutlined } from "@ant-design/icons"
import { Button, Checkbox, Col, Form, Modal, Row, Select, Space } from "antd"
import React, { useEffect, useRef, useState } from "react"

import ReactToPrint from "react-to-print"
import API from "../../../../../API"
import ImprimirReporte from "../ImprimirReporte"
import dayjs from "dayjs"
import 'dayjs/locale/es'

// const ComponentToPrint = React.forwardRef(({ componentToPrint }, ref) => {
//     return (
//         <div ref={ref} style={{ display: 'none' }}>
//             {/* Contenido que deseas imprimir */}
//             {componentToPrint}
//         </div>
//     );
// })

const ModalReporte = ({ docReporte, onCancelReporte, id_reg }) => {
    const [loading,setoading] = useState(false)
    const [subRegistro, setSubRegistros] = useState([])
    const [print, setPrint] = useState({})
    const [datos, setDatos] = useState([])
    const [indicadores, setIndicadores] = useState([])
    const [historial, setHistorial] = useState(false)
    const [pay, setPay] = useState({})
    const [idReporte,setIdReporte] = useState(0)
    const orden_contenido = useRef(null)
    const [form] = Form.useForm()


    dayjs.locale('es')

    const listaSubRegistros = async () => {
        try {
            const { data } = await API.get('api/registro/subregistro/', { params: { id_reg } })
            setSubRegistros(data)
        } catch (error) {
            console.log(error)
        }
    }

    const buscar = async (payload) => {
        console.log(payload)
        const filtros = {
            sub_reg: payload.sub_registros,
            // fecha_inicial: payload.fecha_inicial,
            // fecha_fin: payload.fecha_fin,
            idInd: payload.indicador
        }
        setPrint(filtros)
        try {
            setPay(payload)
            const { data } = await API.get('api/registro/subregistro/',
                {
                    params:
                    {
                        id: payload.sub_registros,
                        id_reg: id_reg,
                    }
                })
            setDatos(data)

            // await API.post('api/reportes/reporte/', {
            //     id_reg,
            //     id_sub: payload.sub_registros,
            //     id_ind: payload.indicador
            //     // fecha_inicio: payload.fecha_inicio,
            //     // fecha_fin: payload.fecha_fin
            // })
        } catch (error) {
            console.log(error)
        }
    }

    const guardarHistorial = async()=>{
        try {
            if(historial){
               const {data} = await API.post('api/reportes/reporte/', {
                    id_reg,
                    id_sub: pay.sub_registros,
                    id_ind: pay.indicador
                    // fecha_inicio: payload.fecha_inicio,
                    // fecha_fin: payload.fecha_fin
                })
                setIdReporte(data?.id)
            }else{
                if(idReporte>0){
                    console.log(idReporte)
                    const {data} = await API.delete(`api/reportes/reporte/${idReporte}/`)
                    setIdReporte(0)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    const busIndicadores = (payload) => {
        console.log(payload)
        let temp = subRegistro?.filter(e => e?.id === payload)?.map(e => e?.indicadores)[0]
        setIndicadores(temp)

    }

    const required = { required: true, message: 'Complete este campo' }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current
    // })

    // const customComponent = () => {
    //     return <ImprimirReporte datos={datos} print={print} />
    // }



    useEffect(() => {
        listaSubRegistros()
    }, [])

    useEffect(() => {
        setDatos([])
        setHistorial(false)
        setIdReporte(0)
        form.resetFields()
    }, [docReporte])

    useEffect(()=>{
        guardarHistorial()
    },[historial])



    // useEffect(() => {
    //     const handlePrint = () => {
    //         if (orden_contenido.current) {
    //             orden_contenido.current.handlePrint();
    //         }
    //     };
    //     handlePrint();
    // }, [print])

    // useEffect(()=>{
    //     if(subRegistro){
    //         const temp = []
    //     }
    // },[subRegistro])



    return <Modal maskClosable={false} title='Generar Reporte' closable={!loading} open={docReporte} onCancel={onCancelReporte} okButtonProps={{ style: { display: 'none' } }} cancelButtonProps={{ style: { display: 'none' } }} width={800} style={{ width: 800 }}>
        <Form layout="vertical" onFinish={buscar} form={form} disabled={false}>
            <Row>
                <Col span={24}>
                    <Form.Item name='sub_registros' label='Selecciona los subregistros' rules={[required]}>
                        <Select
                            placeholder='Subregistros'
                            options={subRegistro?.map(e => ({ value: e?.id, label: e?.sub_registro }))}
                            filterOption={filterOption}
                            showSearch
                            onChange={busIndicadores}
                        />
                    </Form.Item>
                </Col>
                <Col span={24} >
                    <Form.Item name='indicador' label='Indicador'>
                        <Select
                            filterOption={filterOption}
                            showSearch
                            placeholder='Indicadores'
                            options={indicadores?.map(e => ({ value: e?.id, label: e?.indicador }))}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>

                        <div>
                            <Button type="primary" htmlType="submit" onClick={buscar}>Vista Previa</Button>
                        </div>
                    </Space>
                </Col>
                {/* <Col span={12}>
                    <Form.Item name='fecha_inicial' label='Fecha de Inicio'>
                        <DatePicker />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='fecha_fin' label='Fecha de Fin'>
                        <DatePicker />
                    </Form.Item>
                </Col> */}
            </Row>
        </Form>
        <div ref={el => (orden_contenido.current = el)}>
            <ImprimirReporte datos={datos} print={print} />
        </div>
        {datos.length > 0 ? <>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Space>
                    <div id="imprimir">
                        <ReactToPrint
                            documentTitle={`Reporte generado el dia ${dayjs()}`}
                            trigger={() => {
                                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                // to the root node of the returned component as it will be overwritten.

                                return <Button type="primary" icon={<PrinterOutlined />}>Imprimir</Button>;
                            }}

                            content={() => orden_contenido.current}
                        />
                    </div>
                    <Checkbox onChange={({ target }) => setHistorial(target.checked)}>Guardar en Historial</Checkbox>
                </Space>
            </div>

        </> : null
        }
        {/* <div>
            <ReactToPrint
                trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return <Button type="primary" icon={<PrinterOutlined />}>Imprimir</Button>;
                }}

                content={() => orden_contenido.current}
            />
            <div ref={el => (orden_contenido.current = el)}>
                <ImprimirReporte datos={datos} print={print} />
            </div>
        </div> */}

        {/* <div>
            <Button type="primary" onClick={() => handlePrint({ ComponentToPrint: customComponent })} icon={<PrinterOutlined />}>Imprimir</Button>

            <ComponentToPrint ref={componentRef} componentToPrint={customComponent}/>
        </div> */}
    </Modal>
}

export default ModalReporte