import { LeftOutlined, PlusOutlined, RightOutlined, StarFilled } from "@ant-design/icons"
import { Breadcrumb, Button, Card, Col, Row, Space, Typography } from "antd"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import API from "../../../API"
import { COLOR, ISMOBILE } from "../../../constantes"
import CrearRegistro from "../../Home/Modals/CrearRegistro"
import ModalReporte from "./components/ModalReporte"
import useAuth from "../../../hooks/Auth"


const VentanaSubRegistros = () => {
    const {user} = useAuth()
    const { id, name } = useParams()
    const [datos, setDatos] = useState([])
    const [doc, setDoc] = useState(null)
    const [docReporte, setDocReporte] = useState(null)
    const [reg,setReg] = useState({})
    const navigate = useNavigate()

    const sub = async () => {
        try {
            const { data } = await API.get(`api/registro/subregistro/`, { params: { id_reg: id } })
            setDatos(data)
        } catch (error) {
            console.log(error)
        }
    }

    const petReg = async ()=>{
        try {
            const {data} = await API.get(`api/registro/registro/${id}/`)
            setReg(data) 
        } catch (error) {
            console.log(error)
        }
    }
    
    const onOpen = () => {
        setDoc({})
    }

    const onOpenReporte = ()=>{
        setDocReporte({})
    }

    const onCancel = () => {
        setDoc(null)
        sub()
    }

    const onCancelReporte = ()=>{
        setDocReporte(null)
    }

    useEffect(() => {
        sub()
        petReg()
    }, [])

    return <div style={{ padding: 32, backgroundColor: COLOR }}>
        <Space>
            <Button onClick={()=>navigate('/home')} type="text" icon={<LeftOutlined/>}/>
            <Breadcrumb

                items={[
                    {
                        title: 'Mis Registros'
                    }
                ]}
            />
        </Space>
        <Row>
            <>
                <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: ISMOBILE ? 'column' : '' }}>
                    <div style={{ marginLeft: 30 }}>
                        <Typography.Title level={ISMOBILE ? 4 : 2}>{name}</Typography.Title>
                    </div>
                    <div style={{ marginLeft: ISMOBILE ? 60 : '', marginTop: 28, display: 'flex', flexDirection: ISMOBILE ? 'column' : 'row' }}>
                        {
                            user?.user?.imprimir ? <>
                                <Button style={{ marginTop: ISMOBILE ? 10 : '', marginRight: ISMOBILE ? '' : 30, width: ISMOBILE ? 200 : '' }} onClick={() => onOpenReporte()} icon={<StarFilled />}>Generar Reporte</Button>
                            </>:null
                        }
                        <Button style={{ marginTop: ISMOBILE ? 10 : '', marginRight: ISMOBILE ? '' : 30, width: ISMOBILE ? 200 : '' }} onClick={() => onOpen()} icon={<PlusOutlined />}>Añadir Subregistro</Button>
                    </div>
                </Col>
                {
                    datos?.map(e => (
                        <Col span={24} style={{ width: '100%' }} key={e?.id}>
                            <Card style={{ margin: 30, marginRight: 30 }} >
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <div>
                                        <Typography.Title level={ISMOBILE ? 4 : 3}>{e?.sub_registro}</Typography.Title>
                                    </div>
                                    <div style={{ marginTop: ISMOBILE ? '10%' : '2%' }}>
                                        <Button type="text" onClick={() => navigate(`/subregistro/${e?.id}`)} icon={<RightOutlined />} />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    ))
                }
            </>
        </Row>
        <CrearRegistro doc={doc} onCancel={onCancel} cont={1} id_red={id} subRegis={reg?.otro ? 0 : reg?.fk_tipo?.id}/>
        <ModalReporte docReporte={docReporte} onCancelReporte={onCancelReporte} id_reg={id} vol={true}/>
    </div>
}

export default VentanaSubRegistros