import { MenuUnfoldOutlined } from "@ant-design/icons"
import { Button, Dropdown, Layout, Menu, Typography } from "antd"
import { Content, Header } from "antd/es/layout/layout"
import { useState } from "react"
import { Link, Outlet, useNavigate } from "react-router-dom"
import { ISMOBILE } from "../../constantes"
import useAuth from '../../hooks/Auth'
import Registro from "../../Pages/Bienvenida/components/Registro"
import logo_nombre from '../../assets/imagenes/logo_blanco.png'





const ProtedPage = () => {
    const { logout, user } = useAuth()
    const [doc, setDoc] = useState(null)
    const navigate = useNavigate()


    console.log(user)

    const onCancel = () => {
        setDoc(null)
    }
    const items2 = [
        {
            key: 1,
            label: <Link to='/diagnostico'>Diagnóstico</Link>
        },
        {
            key: 2,
            label: <Link to='/home'>Registros</Link>
        },
        {
            key: 3,
            label: <Link to='/directorio'>Directorio</Link>
        },
        {
            key: 4,
            label: <Link to='/historial-reporte'>Historial de Reportes</Link>
        },
        {
            key: 5,
            label: <Typography.Text style={{ color:'#fff' }} onClick={() => setDoc(user)}>Mi cuenta</Typography.Text>
        },
        {
            key: 6,
            label: <Typography.Text style={{ color: '#fff' }} onClick={() => logout()}>Salir</Typography.Text>
        }
    ]

    // const items1 =['Diagnostico','Directorio','Mi cuenta'].map((key,i)=>({
    //     key:1+i,
    //     label:key
    // }))

    const ismobile = window.matchMedia('(max-width: 768px)').matches;
    console.log(ismobile)

    return <Layout chasSider>
        <Header style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#65b02a',
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 4,
            },
            shadowOpacity: 0.30,
            shadowRadius: 4.65,

            elevation: 8,
        }}>
            <div style={{ marginTop: ISMOBILE ? '8%' : '1.9%' }}>
                {/* Typography.Title style={{ color: '#fff', cursor: 'pointer' }} onClick={() => navigate('/home')}>Sostynet</Typography.Title> */}
                <img
                    src={logo_nombre}
                    style={{ width: 100, height: 52, cursor: 'pointer' }}
                    onClick={() => navigate('/home')}
                />
            </div>
            <div>
                {
                    ismobile == false ? <>
                        <Menu
                            mode="horizontal"
                            theme="light"
                            // defaultSelectedKeys={['2']}
                            items={items2}
                            style={{ backgroundColor: '#65b02a', color: '#fff' }}

                        />
                    </> : <>
                        <Dropdown
                            menu={{ items: items2 }}
                            placement='bottomCenter'
                            arrow

                        >
                            <Button shape="circle" icon={<MenuUnfoldOutlined />} />
                        </Dropdown>
                    </>

                }

            </div>
        </Header>
        <Layout
            className="site-layout"
        // style={{
        //     marginLeft: 200,
        // }}
        >

            <Content
                style={{
                    overflow: 'initial',
                }}
            >

                <Outlet />

                <Registro docRegistro={doc} onCancelRegistro={onCancel} />
            </Content>
        </Layout>


        {/* {
                ismobile ? <>
                    <Layout>
                        <Sider style={{
                            left: 0,
                            top: 0,
                            bottom: 0,      
                        }}>
                            <Menu
                                mode="inline"
                                theme="light"
                                style={{backgroundColor:'#0DBCCB'}} 
                                // defaultSelectedKeys={['1']}
                                // defaultOpenKeys={['sub1']}
                                // style={{
                                //     height: '100%',
                                // }}
                                items={items2}
                            />
                        </Sider>
                        <Content style={{
                            overflow: 'initial',
                            minHeight: '100vh',
                        }}><Outlet /></Content>
                    </Layout>
                </> : <Content>
                    <Outlet />
                </Content>
            } */}
    </Layout>
}

export default ProtedPage