import dayjs from "dayjs"
import { useState } from "react"
import Grafica from "../../../../../components/ProtedPage/Grafica"
import { ISMOBILE, comparadorFecha } from "../../../../../constantes"
import { Typography } from "antd"

const ImprimirReporte = ({ datos, print = false,arr=null }) => {
    const [realPorc,setRealPorc] = useState('')
    const [idealPor,setIdealPor] = useState('')

    const datIdeal = (val)=>{
       setIdealPor(val)
    }

    const datReal = (val)=>{
        setRealPorc(val)
    }

    // const guardar = ()=>{

    // }

    // useEffect(()=>{
    //     guardar()
    // },[])
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {
            datos?.map((e, i) => (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <h1>{e?.fk_registro?.otro === null ? e?.fk_registro?.fk_tipo?.tipo : e?.fk_registro?.otro}</h1>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <h2>{e?.sub_registro}</h2>
                        </div>
                        {e?.indicadores?.filter(x => x?.id === print?.idInd)?.map((j, ind) => {
                            return (
                                <div style={{ padding: 20, marginTop: ISMOBILE ? -30 : 30, width: '100%' }}>
                                    <Grafica key={i} data={j} filtro={print} datIdeal={datIdeal} datReal={datReal} indicador={j} arr={arr}/>
                                    <div style={{ marginTop: '-10%', display: ISMOBILE ? 'none' : 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <table border={1} style={{ width: 580 }}>
                                            <tr>
                                                <td><b>Responsable</b></td>
                                                <td colSpan={2}>{j?.responsable}</td>
                                                <td><b>Fecha Final</b></td>
                                                <td colSpan={2}>{j?.fecha_fin}</td>
                                                <td colSpan={2}><b>Fecha Actual</b></td>
                                                <td colSpan={2}>{dayjs().format('DD-MM-YY')}</td>

                                            </tr>
                                            <tr>
                                                <td><b>Precio de Unidad</b></td>
                                                <td colSpan={2}>{j?.valor_unidad}</td>
                                                <td colSpan={2}><b>Fecha de Inicio</b></td>
                                                <td>{j?.fecha_inicio}</td>
                                                <td colSpan={2}><b>Valor de Meta</b></td>
                                                <td>{j?.valor_meta}</td>
                                            </tr>
                                            <tr>
                                                {/* <td colSpan={2}><b>Valor de Meta</b></td>
                                                <td>{j?.valor_meta}</td> */}
                                                <td colSpan={2}><b>% avance ideal</b></td>
                                                <td>{idealPor}%</td>
                                                <td colSpan={3}><b>% avance real</b></td>
                                                <td colSpan={3}>{realPorc}%</td>
                                            </tr>
                                            <tr>
                                                <td><b>Propósito</b></td>
                                                <td colSpan={7}>{j?.proposito}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div style={{marginTop:'5%',display: ISMOBILE ? 'none' : 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <table border={1} style={{ width: 580 }}>
                                            <tr>
                                                <td><b>Fechas</b></td>
                                                <td colSpan={2}><b>valor</b></td>
                                                <td><b>Acciones Tomadas</b></td>
                                                <td><b>Soportes</b></td>
                                            </tr>
                                            {
                                                j?.Datos.sort(comparadorFecha)?.map(x => (
                                                    <tr>
                                                        <td>{x?.fecha_reporte}</td>
                                                        <td colSpan={2}>{x?.valor}</td>
                                                        <td>{x?.comentario}</td>
                                                        <td><Typography.Link href={x?.soporte}>Descargar Soporte</Typography.Link></td>
                                                    </tr>
                                                ))
                                            }
                                        </table>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                        <h2><b>Comentario</b></h2>
                                        <div>
                                            <p style={{ width: 600 }}>
                                                {j?.comentario}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            ))
        }
    </div>
}

export default ImprimirReporte