import { Col, Form, Input, Row, Tooltip } from "antd"


const PPS = () => {
    return <div style={{ width: '100%' }}>
        <Row gutter={32}>
            <Col span={12}>
                <Tooltip 
                    trigger={['focus']}
                    title='Enlace video de youtube, Maximo 90 segundos'
                    placement="topLeft" 
                >
                    <Form.Item initialValue={''} name='url_video_1' label='Video 1 (Descripción de actividad)'>
                        <Input />
                    </Form.Item>
                </Tooltip>
            </Col>
            <Col span={12}>
            <Tooltip 
                    trigger={['focus']}
                    title='Enlace video de youtube, Maximo 90 segundos'
                    placement="topLeft" 
                >
                    <Form.Item initialValue={''} name='url_video_2' label='Video 2 (Descripción de producto o servicio)'>
                        <Input />
                    </Form.Item>
                </Tooltip>
            </Col>
            <Col span={12}>
            <Tooltip 
                    trigger={['focus']}
                    title='Enlace video de youtube, Maximo 4 minutos'
                    placement="topLeft" 
                >
                    <Form.Item initialValue={''} name='url_video_3' label='Video 3 Por qué usar el prod/ser, como ayuda a la sostenibilidad)'>
                        <Input />
                    </Form.Item>
                </Tooltip>
            </Col>
        </Row>
    </div>
}

export default PPS