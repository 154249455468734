import { Line } from "react-chartjs-2"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js"
import dayjs from "dayjs";
import { DIA, ISMOBILE, MES, SEMANA, comparadorFecha } from "../../../constantes";
import { useEffect, useState } from "react";



ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

const Grafica = ({ data, filtro, datIdeal, indicador, datReal, arr = null }) => {
    console.log(indicador)
    const [labels, setLabels] = useState([])
    const [datos, setDatos] = useState([])
    const [arrayIdeal, setArrayIdeal] = useState([])
    const [diferenciaInd, setDiferenciaInd] = useState(0)
    const [restar, setRestar] = useState(false)
    const [numPeridos, setNumPeriodos] = useState(0)

    console.log(data, '----grafica')

    const nombre_meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    
    const intervalos = () => {
        let tiempo = [`${data?.intervalo_tiempo?.tiempo}-0`]
        console.log(data)

        let fecha_inicio = dayjs(data?.fecha_inicio)
        let fecha_fin = dayjs(data?.fecha_fin)
        let diferencia_dias = fecha_fin.diff(fecha_inicio, 'day')
        let cont = 0
        setDatos(arr !== null ? arr : data?.Datos?.sort(comparadorFecha))
        if (data?.valor_meta > data?.valor_actual) {
            setDiferenciaInd(data?.valor_meta - data?.valor_actual)
            setRestar(false)
        } else {
            setDiferenciaInd(data?.valor_actual - data?.valor_meta)
            setRestar(true)
        }
        if (data?.intervalo_tiempo.tiempo === SEMANA) {
            for (let i = 0; i < diferencia_dias; i += 7) {
                console.log(cont + 1)
                tiempo.push(`Semana-${cont + 1}`);
                cont += 1
                const semanaInicio = fecha_inicio.add(i, 'day')
                const semanaFin = fecha_inicio.add(Math.min(i + 6, diferencia_dias), 'day')
                // semanas.push(`${semanaInicio.format('YYYY-MM-DD')} - ${semanaFin.format('YYYY-MM-DD')}`);
            }
        } else if (data?.intervalo_tiempo.tiempo === DIA) {
            for (let i = 0; i < diferencia_dias; i++) {
                tiempo.push(`Dia-${i + 1}`)
            }
        } else if (data?.intervalo_tiempo.tiempo === MES) {
            const meses = fecha_fin.diff(fecha_inicio, 'month')
            console.log(fecha_fin.month() + 1)
            const lmeses = [nombre_meses[fecha_inicio.month()]]
            while (fecha_inicio.isBefore(fecha_fin) || fecha_inicio.isSame(fecha_fin, 'day')) {
                const numeroMes = fecha_inicio.add(1, 'month').month()
                const nombreMes = nombre_meses[numeroMes]

                if (!lmeses.includes(nombreMes)) {
                    lmeses.push(nombreMes)
                }
                fecha_inicio = fecha_inicio.add(1, 'month')

                // if(fecha_inicio.month()===0){
                //     fecha_inicio.add(1,'year')
                // }
            }
            tiempo = lmeses
            setNumPeriodos(meses+1)
            // for (let i = 0; i < meses+1; i++) {
            //     tiempo.push(`Mes-${i + 1}`)
            // }
        }
        setLabels(tiempo)
    }
    const dataIdeal = () => {
        const tmLabel = labels.length
        const div = diferenciaInd / (tmLabel - 1)
        console.log(div, '-----div')
        let array = []
        let aux = 0
        if (restar) {
            for (let i = 0; i < tmLabel; i++) {
                if (i == 0) {
                    aux = data?.valor_actual - div
                    array.push(aux)
                } else {
                    aux = aux - div
                    array.push(aux)
                }
            }
        } else {
            for (let i = 0; i < tmLabel; i++) {
                if (i == 0) {
                    aux = data?.valor_actual + div
                    array.push(aux)
                } else {
                    aux = aux + div
                    array.push(aux)
                }
            }
        }
        setArrayIdeal([data?.valor_actual, ...array])
    }

    const options = {
        responsive: true,
        tooltips: {
            callbacks: {
                title: (tooltipItems, data) => {
                    // Puedes personalizar el título del tooltip aquí
                    return 'Título Personalizado';
                },
                label: (tooltipItem, data) => {
                    // Obtén el dataset actual y el valor del punto
                    const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                    const valorPunto = tooltipItem.yLabel || '';

                    // Obtén información adicional del punto si la tienes
                    const infoAdicional = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]?.info || '';

                    // Personaliza el contenido del tooltip aquí
                    return `${datasetLabel}: ${valorPunto} - ${infoAdicional}`;
                },
            },
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: data?.indicador
            },
        },
        scales: {
            x: {
                display: true,
                title: {
                    display: true,
                    text: 'Tiempo'
                }
            },
            y: {
                display: true,
                title: {
                    display: true,
                    text: data?.unidad_otro === null ? data?.unidad?.unidad : data?.unidad_otro
                }
            }
        },
    };



    const dt = datos?.map(e => { return { y: e?.valor, info: 'lo que sea' } }) || [];
    console.log(dt)
    const charData = {
        labels: labels,

        datasets: [
            {
                label: 'Linea Meta',
                data: arrayIdeal,
                borderColor: 'rgb( 125, 206, 160 )',
                backgroundColor: 'rgba( 125, 206, 160 , 0.5)',

            },
            {
                label: 'Linea Real',
                data: datos?.map(e => e?.valor),
                borderColor: 'rgba(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
        ]
    }

    const filtroFecha = (fecha_in, fecha_fin) => {
        const fechaIn = new Date(fecha_in)
        const fechaFin = new Date(fecha_fin)
        console.log(fechaIn, '-----fechas')

        const temp = datos?.filter(e => {
            const fb = new Date(e?.fecha_reporte)
            console.log(fb)
            return fb >= fechaIn && fb <= fechaFin
        })

        setDatos(temp)
    }

    useEffect(() => {
        intervalos()
    }, [])

    useEffect(() => {
        if (diferenciaInd !== 0) {
            dataIdeal()
        }
    }, [diferenciaInd])

    useEffect(() => {
        if (filtro.fecha_inicial !== undefined && filtro.fecha_inicial !== null && filtro.fecha_fin !== undefined && filtro.fecha_fin !== null) {
            filtroFecha(filtro.fecha_inicial, filtro.fecha_fin)
        }
    }, [filtro])

    useEffect(() => {
        if (datos.length > 1) {
            console.log(datos[0]?.valor)
            let mat = ((datos[datos.length - 1]?.valor - datos[0]?.valor) / (indicador?.valor_meta - indicador?.valor_actual) * 100)
            datReal(parseFloat(mat).toFixed(2))
        }
    }, [datos])

    useEffect(() => {
        if (arrayIdeal.length > 1) {
            let temp = arrayIdeal.slice(0, -1)
            console.log(datos.length)
            //let math = ((diferenciaInd*datos.length/(indicador.valor_actual-indicador.valor_meta))*100)
            let math = (100 / (numPeridos)) * (datos.length - 1)
            datIdeal(parseFloat(math).toFixed(2))
        }
    }, [arrayIdeal])



    return <div style={{ padding: ISMOBILE ? 10 : 0, display: 'flex', justifyContent: 'center', marginBottom: 10, width: ISMOBILE ? 400 : 800, height: 500 }}>
        <Line data={charData} options={options} style={{ display: 'flex', width: 500, height: 800 }} />
    </div>
}

export default Grafica