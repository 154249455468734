import { UploadOutlined } from "@ant-design/icons"
import { Button, Col, Form, Input, InputNumber, Row, Select, Upload } from "antd"
import { useEffect, useState } from "react"
import useSWR from 'swr'
import API from "../../../../../../API"



const PersonaNatural = ({handleChange,pais=0,Dataciudad=0,perfil=false}) => {
    const [ciudad, setCiudad] = useState({})


    const fetcher = (url) => {
        return API(url).then(d => d?.data)
    }

    const paises = useSWR('api/configuracion/paises/', fetcher)
    const identificacion = useSWR('api/configuracion/identificacion/', fetcher)


    

    const Ciudades = async(fk_pais)=>{
        try {
            const {data} = await API.get(`api/configuracion/ciudad/`,{params:{fk_pais:fk_pais}})
            setCiudad({ciudades:data})
        } catch (error) {
            console.log(error)
        }
    }
    

    const buscarCiudad = (dato) => {
       if(perfil){
            console.log('ingfresa en el if','----',dato)
            Ciudades(dato)
       }else{
            console.log('en el else')
            setCiudad(...paises?.data?.filter(e => e?.id === dato))
       }
    }

    const required = { required: true, message: 'Complete este campo' }

    useEffect(()=>{
        console.log(pais)
        if(pais>0){
            buscarCiudad(pais)
        }
    },[pais])

    return <div style={{ width: '100%' }}>
        <Row gutter={32}>
            <Col span={12}>
                <Form.Item name='identificacion' label='Identificacion' rules={[required]}>
                    <Select
                        placeholder='Identificación'
                        options={identificacion?.data?.map(e => ({ value: e?.id, label: e?.identificacion }))}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='numero_documento' label='Num. Documento' rules={[required]}>
                    <InputNumber style={{ width: 214 }} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='numero_telefono' label='Num. Teléfono' rules={[required]}>
                    <InputNumber style={{ width: 214 }} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='pais' label='País' rules={[required]}>
                    <Select
                        placeholder='País'
                        options={paises?.data?.map(e => ({ value: e?.id, label: e?.paises }))}
                        onChange={buscarCiudad}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='ciudad' label='Ciudad' rules={[required]}>
                    <Select
                        placeholder='Ciudad'
                        options={ciudad?.ciudades?.map(e => ({ value: e?.id, label: e?.ciudad }))}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='sede' label='Dirección de Sede' rules={[required]}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='oficio' label='Oficio o lugar de Trabajo' rules={[required]}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='actividad' label='Actividad' rules={[required]}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='pagina_web' label='Pagina web'>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='logo' label='Logo'>
                    <Upload
                        maxCount={1}
                        // beforeUpload={beforeUpload}
                        onChange={(info)=>handleChange(info)}
                    >
                        <Button icon={<UploadOutlined />}>Cargar logo</Button>
                    </Upload>
                </Form.Item>
            </Col>
        </Row>
    </div>
}

export default PersonaNatural