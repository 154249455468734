import { MailOutlined, PhoneOutlined } from '@ant-design/icons'
import { Button, Col, Row, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'

import { ISMOBILE } from '../../../constantes'
import './style_card.css'

const Cards = ({ data }) => {
    console.log(data?.logo)
    const navigate = useNavigate()
    return <>
        <div>
            <div className='budget'>
                <Row >
                    <Col span={12}>
                        <div style={{marginTop:30,marginLeft:10}}>
                            {
                                data?.logo !== null ? <>
                                    <img className='img img-card' src={`${data?.logo}`} />
                                </> :<>
                                    <img className='img img-card' src="https://cdn-icons-png.flaticon.com/512/847/847969.png" />
                                </>

                            }
                            
                        </div>
                    </Col>
                    <Col span={12} style={{ display: 'flex', flexDirection: 'column', marginLeft: ISMOBILE ? 0 : -15 }}>
                        <div style={{ marginBottom: 10, marginTop: -22 }}>
                            <Typography.Title level={5}>{data?.nombre_empresa}</Typography.Title>
                        </div>
                        <div>
                            <Typography.Text>{data?.descripcion}</Typography.Text>
                        </div>
                        <div style={{ marginTop: 5 }}>
                            <Typography.Paragraph><PhoneOutlined />  {data?.numero_telefono}</Typography.Paragraph>
                            <Typography.Paragraph><MailOutlined />  {data?.fk_user?.email}</Typography.Paragraph>
                            <Typography.Paragraph><img src='https://cdn-icons-png.flaticon.com/512/2838/2838912.png' width={18} />  {data?.direccion_empresa}</Typography.Paragraph>
                        </div>
                    </Col>
                    <Col span={24} style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:ISMOBILE ? -25 :-55}}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent:'space-around' }}>
                            {
                                data?.saber_mas !== null ? <Button onClick={()=>window.open(`${data?.saber_mas}`, "_blank")}>Saber mas</Button> : null
                            }
                            {
                                data?.pagina_web !== null ? <Button onClick={()=>window.open(`${data?.pagina_web}`, "_blank")}>Sitio web</Button>:null
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </>
}

export default Cards