import { InboxOutlined } from "@ant-design/icons"
import { Col, DatePicker, Form, Input, InputNumber, message, Modal, notification, Row, Upload } from "antd"
import dayjs from "dayjs";
import { useEffect, useState } from "react"
import API from "../../../../API";




const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
    const isLt2M = file.size 
    if (isLt2M < 100) {
        message.error('El archivo supera los 100MB');
    }
    return false;
};


const AddDatos = ({ docReporte, onCancelReporte, id_ind, onlistDatos }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    // const {updateAdd} = useUpdateAddatos()

    const onOk = ()=>{
        form.submit()
    }

    const archivo = (info) => {
        console.log(info.file)
        if(info.file.status !== 'removed'){
            getBase64(info.file, (url) => {
                // subir(url)
                form.setFieldsValue({
                    soporte:url
                })
               
            });
        }

        // if (info.file.status === 'uploading') {
        //     setLoading(true);
        //     return;
        // }
        // if (info.file.status === 'done') {
        //     // Get this url from response in real world.
        //     getBase64(info.file.originFileObj, (url) => {
        //         setLoading(false);
        //         setBase64(url)
        //         console.log(url,'-----url')
        //     });
        // }
    }

    const guardar = async (payload) => {
        try {
            console.log(payload)
            const method = docReporte?.id ? 'put' : 'post'
            const url = docReporte?.id ? `api/registro/datos/${docReporte?.id}/actualizar/` : 'api/registro/datos/guardar-dato/'
            const { data } = await API({
                url, method, data:{
                    id_ind:id_ind,
                    fecha_reporte:payload.fecha_reporte,
                    valor:payload.valor,
                    comentario:payload.comentario,
                    soporte:payload.soporte
                }
            })
            onCancelReporte();
            // onlistDatos();

            notification.open({
                type:'success',
                message: docReporte?.id ? 'Dato Actualizado' : 'Dato Guardado'
            })
           
            

            // const {data} = await API.post('api/registro/datos/guardar-dato/',{
            //     id_ind:id_ind,
            //     fecha_reporte:payload.fecha_reporte,
            //     valor:payload.valor,
            //     comentario:payload.comentario,
            //     soporte:payload.soporte
            // })
            // const {data} = await API.post('api/registro/dato/guardar-dato/',payload)
            // onCancelReporte()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        if(docReporte){
            console.log(docReporte)
            form.setFieldsValue({
                fecha_reporte: dayjs(docReporte?.fecha_reporte),
                valor:docReporte?.valor,
                comentario:docReporte?.comentario
            })
        }else{
            form.resetFields()
        }
    },[docReporte])

    // useEffect(()=>{
    //     if(updateAdd){
    //         lisD
    //     }
    // },[updateAdd])

    const required = { required: true, message: 'Complete este campo' }

    return <Modal onOk={onOk} title='Datos a reportar' maskClosable={false} closable={!loading} open={docReporte} onCancel={onCancelReporte} okText='Guardar' cancelButtonProps={{ style: { display: 'none' } }} width={600} style={{ width: 800 }}>
        <Form layout="vertical" form={form} onFinish={guardar}>
            <Row>
                <Col span={12}>
                    <Form.Item name='valor' label='Valor' required={[required]}>
                        <InputNumber placeholder="000" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='fecha_reporte' label='Fecha reporte' required={[required]}>
                        <DatePicker />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name='comentario' label='Acciones Tomadas' required={[required]}>
                        <Input.TextArea />
                    </Form.Item>
                </Col>
                <Col span={24} style={{ marginBottom: 30 }}>
                    <Form.Item name='soporte'>
                        <Upload.Dragger
                            maxCount={1}
                            onChange={archivo}
                            beforeUpload={beforeUpload}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Subir Evidencias</p>
                        </Upload.Dragger>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Modal>
}

export default AddDatos