import { Button, Col, Row, Typography } from "antd"
import { Footer } from "antd/es/layout/layout"
import { useEffect, useState } from "react"
import Login from "../Login"
import Registro from "./components/Registro"
import './style_bienvenida.css'
import inicio from '../../assets/imagenes/inicio.png'
import logo_nombre from "../../assets/imagenes/logo_nombre.png"
import logo from "../../assets/imagenes/logo.png"
import RecuperarCuenta from "./components/RecuperarCuenta"
import { ISMOBILE } from "../../constantes"
const Bienvenida = () => {

    const [doc,setDoc] = useState(null)
    const [docRegistro,setDocRegistro] = useState(null)
    const [docCuenta,setDocCuenta] = useState(null)

    const onCancel = ()=>{
        setDoc(null)
    }

    const onRegistro = ()=>{
        setDocRegistro({})
        onCancel()
    }

    const onDiagnostico = ()=>{
        setDoc({})
        window.sessionStorage.setItem('pathname','/diagnostico')
    }

    const onCuenta = ()=>{
        setDocCuenta({})
        onCancel()
    }

    const onCancelCuenta = ()=>{
        setDocCuenta(null)
    }

    const onCancelRegistro = ()=>{
        setDocRegistro(null)
    }

    // useEffect(()=>{
    //     localStorage.removeItem("token")
    // },[])
    return <div style={{  }}>
        <Row>
            <Col span={12}>
                {/* <Typography.Title level={2}>Systonet</Typography.Title> */}
                <img src={logo_nombre} style={{width:150,height:100}} alt="logo-nombre"/>
            </Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'end', marginTop: 28 }}>
                <Button style={{}} type='primary' onClick={()=>setDoc({})}>Iniciar Sesión</Button>
            </Col>
        </Row>
        <Login doc={doc} onCancel={onCancel} onRegistro={onRegistro} onCuenta={onCuenta}/>
        <Registro docRegistro={docRegistro} onCancelRegistro={onCancelRegistro}/>
        <RecuperarCuenta docCuenta={docCuenta} onCancelCuenta={onCancelCuenta}/>
        <Row>
            <Col span={24} style={{width:'100%'}}>
                <div id='content-principal'>
                    <div id="div-text">
                        <Typography.Title style={{color:'#64B02A',fontWeight:'bold'}} level={4}>ÚNETE A LA SOSTENIBILIDAD</Typography.Title>
                        <Typography.Title style={{fontWeight:'bold'}}>
                            Lleva el registro ambiental de tu empresa
                        </Typography.Title >
                        <br />
                        <Typography.Text style={{fontWeight:'bold'}}>
                            Optimice su huella ecológica con nuestros servicios de gestión de registro,
                            un paso crucial hacia un futuro mas verde.
                        </Typography.Text>

                        {/* <Button size="large" style={{ marginTop: 30, width: 200 }} type="primary">Comenzar</Button> */}
                    </div>
                    <div id='content-img'>
                        <img id='img-bienvenida' src={inicio} style={{width:'100%',height: ISMOBILE ? '60%':'100%'}} alt="inicio"/>
                    </div>
                </div>
            </Col>
        </Row>
        <br /><br />
        <Row>
            <Col span={24}>
                <div id="content-second">
                    <div id="title">
                        <Typography.Title style={{color:'#fff',fontWeight:'bold'}}>¿Sabes cómo está la sostenibilidad ambiental de tu empresa?</Typography.Title>
                        <Typography.Text style={{color:'#fff',fontWeight:'bold'}}>Conoce tu nivel de impacto ambiental con nuestro diagnóstico gratuito y toma la acción para ser parte de la sustentabilidad </Typography.Text>
                        <div style={{display:'flex',justifyContent:'center'}}>
                            <Button  onClick={onDiagnostico} size="large" style={{ width: 200, marginTop: 30,backgroundColor:'#fff' }}><b>Hacer Diagnóstico</b></Button>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
        <br/>
        <Footer style={{ textAlign: 'center' }}>
            <img src={logo} style={{width:80,height:50}} alt="logo"/>
        </Footer>
    </div>
}

export default Bienvenida