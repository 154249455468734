import axios from "axios";

//export const BASE_URL= 'http://192.168.1.40:8000/' 
export const BASE_URL= 'https://panel.sostynet.org/' 
const path = window.sessionStorage.getItem('pathname')
console.log(path)
console.log(BASE_URL)

const API=axios.create({
    baseURL:BASE_URL,
    headers:{
        Accept: 'application/json',
        "Content-Type": "application/json",
        
    }

})
    const arrApi = [
            'api/configuracion/paises/',
            'api/configuracion/identificacion/',
            'api/configuracion/tipo_registro/',
            'api/configuracion/ciudad/',
            // 'api/user/usuario/user-detail',
            'api/user/usuario/registro/',
            'api/user/usuario/envio-token/',
            'api/user/usuario/validacion-token/',
            'api/user/usuario/cambio-password/',
            'api/user/usuario/login/',
        ]


    API.interceptors.request.use(
        async (config)=> {
            const tokenUsuario = await localStorage.getItem('token')
            
            if (tokenUsuario && !arrApi.includes(config.url)) {
                config.headers['Authorization'] = `Bearer ${tokenUsuario}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    
    )




export default API
