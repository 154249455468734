import { PlusOutlined, RightOutlined } from "@ant-design/icons"
import { Button, Card, Col, notification, Row, Typography } from "antd"
import { useEffect, useState } from "react"

import { useNavigate } from "react-router-dom"
import API from "../../API"
import { COLOR, ISMOBILE } from "../../constantes"

import CrearRegistro from "./Modals/CrearRegistro"




const Home = () => {

    const [doc, setDoc] = useState(null)
    const [datos, setDatos] = useState([])
    const navigate = useNavigate()

    const onCancel = () => {
        setDoc(null)
        registros()
    }

    const onOpen = () => {
        setDoc({})
    }

    const registros = async () => {
        try {
            const { data } = await API.get('api/registro/registro/')
            setDatos(data)
        } catch (error) {
           
        }
    }
  
    const verRegistros = () => (
        <div style={{ padding: ISMOBILE ? 0 : 30 }}>
            <Row>
                <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ marginLeft: 30 }}>
                        <Typography.Title level={ISMOBILE ? 4 : 2}>Mis Registros</Typography.Title>
                    </div>
                    <div style={{ marginTop: 28 }}>
                        <Button onClick={() => onOpen()} icon={<PlusOutlined />}>Añadir Registro</Button>
                    </div>
                </Col>
                <Col span={24}>
                    {
                        datos?.map(e => (
                            <Card style={{
                                margin: 30,
                                marginRight: 30,
                                backgroundColor: '#fff',
                                shadowColor: "#000",
                                shadowOffset: {
                                    width: 0,
                                    height: 4,
                                },
                                shadowOpacity: 0.30,
                                shadowRadius: 4.65,

                                elevation: 8,
                            }}
                                key={e?.id}
                            >
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <div>
                                        <Typography.Title level={ISMOBILE ? 4 : 3}>{e?.otro == null ? e?.fk_tipo?.tipo : e?.otro}</Typography.Title>
                                    </div>
                                    <div style={{ marginTop: ISMOBILE ? '10%' : '2%' }}>
                                        <Button type="text" onClick={() => navigate(`/subregistros/registro/${e?.id}/name/${e?.otro == null ? e?.fk_tipo?.tipo : e?.otro}`)} icon={<RightOutlined />} />
                                    </div>
                                </div>
                            </Card>
                        ))
                    }
                </Col>
            </Row>
        </div>
    )

    useEffect(() => {
        registros()
    }, [])

    return <div style={{ padding: 20, backgroundColor: COLOR }}>
        {/* <Vacio onOpen={onOpen}/>
        {/* <Cards/> */}
        {verRegistros()}
        <CrearRegistro doc={doc} onCancel={onCancel} />
    </div>
}

export default Home