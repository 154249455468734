import { Col, Form, Input, Typography } from "antd"


const Correo = ()=>{
    const required = { required: true, message: 'Complete este campo' }
    return<>
        <Typography.Title level={5}>Ingrese correo electrónico</Typography.Title>
        <Col span={24}>
            <Form.Item name='email' label='Correo' rules={[required]}>
                <Input/>
            </Form.Item>
        </Col>
    </>
}

export default Correo