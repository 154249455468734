import { createContext, useMemo, useState } from "react";


export const UpdateAddDatosContext = createContext()

const UpdateAddDatosProvider =({children})=>{
    const [updateAdd,setUpdateAdd] = useState(false)

    const value = useMemo(()=>({
        updateAdd,
        setUpdateAdd
    }),[updateAdd])

    return<UpdateAddDatosContext.Provider value={value}>{children}</UpdateAddDatosContext.Provider>
}

export default UpdateAddDatosProvider