import { Button, Col, DatePicker, Form, Input, InputNumber, Modal, notification, Row, Select, Typography } from "antd"
import { useEffect, useState } from "react"
import { ISMOBILE } from "../../../../constantes"
import './style_modal.css'
import useSWR from 'swr'
import API from '../../../../API'
import dayjs from "dayjs"

const CrearRegistro = ({ doc, onCancel, cont = 0, regis = null, update = false, id_ind = 0, idSub = 0, subRegis = 0, id_red = 0 }) => {
    const [loading, setLoading] = useState(false)
    const [avanzar, setAvanzar] = useState(0)
    const [form] = Form.useForm()
    const [tipo, setTipo] = useState([])
    const [reg, setReg] = useState([])
    const [datos, setData] = useState([])
    const tipo_reg = Form.useWatch('registro', form)
    const [x, setX] = useState(null)
    // const [unidad,setUnidad] = useState([])


    const fetcher = (url) => {
        return API(url).then(d => d?.data)
    }

    console.log(cont)

    //const tipo_registro = useSWR('api/configuracion/tipo_registro/',fetcher)
    const intervalo = useSWR('api/configuracion/intervalo/', fetcher)
    const unidades = useSWR('api/configuracion/unidad/', fetcher)

    console.log(unidades)

    const tipo_registro = async () => {
        try {
            const { data } = await API.get('api/configuracion/tipo_registro/')
            setReg(data)
        } catch (error) {
            console.log(error)
        }
    }

    const continuar = (num) => {
        console.log(avanzar)

        setAvanzar(avanzar + num)
        const dt = form.getFieldsValue()
        if (dt.registro || dt.sub_registro) {
            if ((avanzar) === 0) {
                const tem1 = [...datos]
                tem1[0] = { registro: dt }
                setData(tem1)
            } else if ((avanzar) === 1) {
                const tem2 = [...datos]
                tem2[1] = { sub_registro: dt.sub_registro }
                setData(tem2)
            }
        } else {
            notification.open({
                type: 'warning',
                message: 'Favor No dejar Campos vacíos'
            })
        }

    }

    const guardarData = async (payload) => {
        // console.log({indicador:payload,registro:data})
        console.log('--------', datos)
        try{
            if(regis !== null){
                guardarIndicadores(payload)
            }else if(id_red > 0){
                let dt = {
                    ...payload,
                    id_reg:parseInt(id_red),
                    nombre:datos[1]?.sub_registro
                }

                const {data} = await API.post('api/registro/subregistro/guardar-subregistro/',dt)
                onCancel()
            }else{
                const {data} = await API.post('api/registro/registro/guardar-registro/',{indicador:payload,registro:datos})
                onCancel()
            }
        }catch(error){
            console.log(error)
        }
    }
    const guardarIndicadores = async (payload) => {
        try {
            console.log(payload, update, idSub)
            const url = update === true ? `api/registro/indicador/${doc?.id}/actualizar/` : 'api/registro/indicador/guardar-indicador/'
            const method = update === true ? 'put' : 'post'
            const { data } = await API({
                url, method, data: { indicador: payload, id_sub: idSub }
            })
            onCancel()
        } catch (err) {
            // notification.open({
            //     type:'error',
            //     message:error
            // })
            console.log(err)
        }
    }

    const btnContinuar = (volver = false, guardar = false) => {
        return <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: volver ? 'space-between' : 'flex-end', marginTop: 30 }}>
                {
                    volver ? <Button onClick={() => continuar(-1)}>Volver</Button> : ''
                }
                <div>
                    {guardar === false ? <Button onClick={() => continuar(1)}>Continuar</Button> : <Button htmlType="submit">Guardar</Button>}
                </div>
                {/* {
                    guardar ? <Button onClick={() => continuar(-1)}>Volver</Button> : ''
                } */}
            </div>
        </>
    }

    let unidad = tipo?.filter(e => e?.id === datos[0]?.registro?.registro)
    console.log(unidad, '-----', datos)
    // console.log(unidad,'----unidad',datos[0].registro.registro.registro)

    const ModalRegistro = () => (
        <>
            <Typography.Title level={3}>Selecciona el registro</Typography.Title>
            <Typography.Paragraph>Este registro hace referencia al componente de sostenibilidad al que le asociarás elementos y datos</Typography.Paragraph>
            <Form.Item name='registro'>
                <Select
                    placeholder='Registro'
                    options={tipo?.map(e => ({ value: e?.id, label: e?.tipo }))}
                    style={{
                        width: '100%'
                    }}
                />
            </Form.Item>
            {
                tipo_reg === 'otro' ? <>
                    <Form.Item name='otro'>
                        <Input placeholder="Otro" />
                    </Form.Item>
                </> : ''
            }
            {btnContinuar()}
        </>

    )

    const ModalSubregistro = () => (
        <>
            <Typography.Title level={3}>Cree un subregistro</Typography.Title>
            <Typography.Paragraph>El subregistro es un elemento asociado al registro, al cual le añadirás los datos a reportar</Typography.Paragraph>
            <Form.Item name='sub_registro' label='Subregistro'>
                <Input placeholder="Escribir" />
            </Form.Item>
            {/* <Form.Item name='rango_fecha' label='Rango Fecha'>
                <DatePicker.RangePicker/>
            </Form.Item>
            <Form.Item name='valor_meta' label='Valor de Meta'>
                <InputNumber placeholder="0000" />
            </Form.Item> */}
            {cont > 0 ? btnContinuar(false) : btnContinuar(true)}
        </>
    )

    console.log(datos)

    const required = { required: true, message: 'Complete este campo' }
    const ModalIndicadores = () => {
        return <>
            <Typography.Title level={3}>Crea los Indicadores</Typography.Title>
            <Form.Item name='nombre_indicador' rules={[{ required: true, message: 'Complete este campo' }]}>
                <Input placeholder="Nombre del indicador" />
            </Form.Item>
            <Row style={{ margin: ISMOBILE === false ? 30 : '' }}>
                <Col span={ISMOBILE ? 24 : 12}>
                    {/* <Typography.Paragraph className="text-modal">Unidad</Typography.Paragraph> */}
                    <Form.Item name='unidad' label='Unidad' rules={[{ required: true, message: 'Complete este campo' }]}>
                        {
                            datos[0]?.registro?.registro === 'otro' ? <Input placeholder="Unidad" style={{ width: ISMOBILE ? '' : 180 }} /> : <>
                                <Select
                                    placeholder='Unidad'
                                    style={{ width: 100 }}
                                    options={
                                        doc?.id ? x : unidad[0]?.unidad?.map(e => ({ value: e?.id, label: e?.unidad }))
                                    }
                                />
                            </>
                        }
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='intervalo_tiempo' label='Intervalo de Tiempo' rules={[{ required: true, message: 'Complete este campo' }]}>
                        <Select
                            placeholder='Tiempo'
                            options={intervalo?.data?.map(e => ({ value: e?.id, label: e?.tiempo }))}
                            style={{ width: 100 }}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name='fecha_inicio' label='Fecha Inicio' rules={[{ required: true, message: 'Complete este campo' }]}>
                        <DatePicker style={{ width: ISMOBILE ? 130 : '' }} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='valor_actual' label='Valor actual' rules={[{ required: true, message: 'Complete este campo' }]}>
                        <InputNumber />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='fecha_final' label='Fecha Final' rules={[{ required: true, message: 'Complete este campo' }]}>
                        <DatePicker style={{ width: ISMOBILE ? 130 : '' }} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='valor_meta' label='Valor Meta' rules={[{ required: true, message: 'Complete este campo' }]}>
                        <InputNumber />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='valor_unidad' label='Precio de Unidad' >
                        <InputNumber />
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{ margin: ISMOBILE === false ? 30 : '' }}>
                <Col span={24}>
                    <Form.Item name='responsable' label='Responsable' rules={[{ required: true, message: 'Complete este campo' }]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name='proposito' label='Propósito' rules={[{ required: true, message: 'Complete este campo' }]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name='comentario' label='Comentario' >
                        <Input.TextArea />
                    </Form.Item>
                </Col>
            </Row>

            {cont > 1 ? btnContinuar(false, true) : btnContinuar(true, true)}
        </>
    }

    useEffect(() => {
        form.resetFields()
        setAvanzar(0)
        tipo_registro()

    }, [])

    useEffect(() => {
        if (reg) {
            setTipo([...reg, { id: 'otro', tipo: 'Otro' }])
        }
    }, [reg])
    console.log(regis)
    useEffect(() => {
        if (regis !== null) {
            console.log(regis, '---useregis')
            if (regis?.otro !== null) {
                console.log('llega if')
                const tem1 = [...datos]
                tem1[0] = { registro: { registro: 'otro' } }
                setData(tem1)
            } else {
                console.log('else regis', datos)
                const tem1 = [...[datos]]
                tem1[0] = { registro: { registro: regis?.fk_tipo?.id } }
                setData(tem1)
                // console.log('llega else')
                // setData([...datos,{registro:{registro:regis?.fk_tipo?.id}}])
            }
        } else {
            form.resetFields()
        }
    }, [regis])

    useEffect(() => {
        if (doc) {
            console.log(doc, '---efectr')
            form.setFieldsValue({
                nombre_indicador: doc?.indicador,
                intervalo_tiempo: doc?.intervalo_tiempo?.id,
                fecha_inicio: dayjs(doc?.fecha_inicio),
                fecha_final: dayjs(doc?.fecha_fin),
                valor_actual: doc?.valor_actual,
                valor_meta: doc?.valor_meta,
                responsable: doc?.responsable,
                proposito: doc?.proposito,
                valor_unidad:doc?.valor_unidad,
                comentario:doc?.comentario
            })
            if (doc?.unidad_otro !== null) {
                form.setFieldsValue({ unidad: doc?.unidad_otro })
            } else {
                setX(tipo?.filter(j => j?.id === doc?.fk_sub_registro?.fk_registro?.fk_tipo?.id)[0]?.unidad?.map(e => ({ value: e?.id, label: e?.unidad })))
                setData(doc?.fk_sub_registro?.fk_registro?.fk_tipo)
                form.setFieldsValue({ unidad: doc?.unidad?.id })
            }
        } else {
            console.log('else')
            form.resetFields()
        }
    }, [doc])

    console.log(x, '-------', datos)

    console.log(unidad)
    useEffect(() => {
        if (cont > 0) {
            setAvanzar(cont)
        }
    }, [cont])
    console.log(subRegis)
    useEffect(() => {
        if (subRegis > 0) {
            console.log('llega aca')
            setData([{ registro: { registro: parseInt(subRegis) } }])
        } else {
            setData([{ registro: { registro: 'otro' } }])
        }
    }, [subRegis])



    console.log(unidad, '----', datos)

    return <Modal maskClosable={false} closable={!loading} open={doc} onCancel={onCancel} okButtonProps={{ style: { display: 'none' } }} cancelButtonProps={{ style: { display: 'none' } }} width={600} style={{ width: 800 }}>
        <Form form={form} layout="vertical" onFinish={guardarData}>
            {avanzar === 0 && ModalRegistro()}
            {avanzar === 1 && ModalSubregistro()}
            {avanzar === 2 && ModalIndicadores()}
        </Form>

    </Modal>
}

export default CrearRegistro