import { DeleteOutlined, LeftOutlined, PlusOutlined, SettingOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Col, Collapse, Popconfirm, Row, Space, Typography, notification } from "antd"
import { useEffect, useState } from "react"
import {  useParams } from "react-router-dom"
import API from "../../API"
import { COLOR, ISMOBILE } from "../../constantes"
import useUpdateAddatos from "../../hooks/UpdateAddDatos"
import CrearRegistro from "../Home/Modals/CrearRegistro"
import AddDatos from "./Componentes/AddDatos"
import TablaCollapse from "./Componentes/TablaCollapse"


const Subregistros = () => {
    const { id } = useParams()
    const { setUpdateAdd } = useUpdateAddatos()
    const [datos, setDatos] = useState({})
    const [doc, setDoc] = useState(null)
    const [docReporte, setDocReporte] = useState(null)
    const [idInd, setIdInd] = useState(0)
    const [registro, setRegistro] = useState({})
    const [idSub, setIdSub] = useState(0)
    const [update, setUpdate] = useState(false)


    const onCancel = () => {
        setDoc(null)
        setUpdate(false)
        sub()
    }

    const onOpen = (reg, id) => {
        console.log(reg, '-----', id)
        setDoc({})
        setRegistro(reg)
        setIdSub(id)
        setUpdate(false)
    }

    const onCancelReporte = () => {
        setUpdateAdd(true)
        setDocReporte(null)
        // window.location.reload()
    }

    const onOpenReporte = (id_ind) => {
        setDocReporte({})
        console.log(id_ind)
        setIdInd(id_ind)
        setUpdateAdd(false)

    }

    const sub = async () => {
        try {
            const { data } = await API.get(`api/registro/subregistro/${id}/`)
            setDatos(data)
        } catch (error) {
            console.log(error)
        }
    }

    const updateIndicador = (doc) => {
        setDoc(doc)
        setUpdate(true)
    }

    const eliminar = async (id) => {
        try {
            const { data } = await API.delete(`api/registro/indicador/${id}/eliminar/`)
            notification.open({
                type:'success',
                message:'Indicador Eliminado con éxito'
            })
            sub()
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        sub()
    }, [])

    return <div style={{ padding: 30, backgroundColor: COLOR }}>
        <Row>
            <>
                <Space>
                    <Button type='text' onClick={() => window.history.back()} icon={<LeftOutlined />} />
                    <Breadcrumb
                        items={[
                            {
                                title: datos?.fk_registro?.otro !== null ? datos?.fk_registro?.otro : datos?.fk_registro?.fk_tipo?.tipo
                            }
                        ]}
                    />
                </Space>
                <Col span={24} style={{ margin: ISMOBILE ? 10 : 30, display: 'flex', justifyContent: ISMOBILE ? 'center' : '' }} >
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: ISMOBILE ? 'column' : 'row' }}>
                        <div>
                            <Typography.Title level={ISMOBILE ? 4 : 2}>{datos?.sub_registro}</Typography.Title>
                        </div>
                        <div style={{ marginRight: 30, marginTop: ISMOBILE ? '' : 30 }}>
                            <Button type="primary" onClick={() => onOpen(datos?.fk_registro, datos?.id)} icon={<PlusOutlined />}>Añadir Indicador</Button>
                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    {
                        datos?.indicadores?.map(j => (
                            <Collapse
                                style={{ marginBottom: 20 }}
                                key={j?.id}
                                items={[{
                                    key: j?.id,
                                    label: <>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: -30, justifyContent: 'space-between' }}>
                                            <div>
                                                <Typography.Title level={4}>{j?.indicador}</Typography.Title>
                                            </div>
                                        </div>
                                    </>,
                                    children: <>
                                        <div>

                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                            <div>
                                                <Typography.Text>Indicador</Typography.Text>
                                            </div>
                                            <div>
                                                <Button type="primary" shape="circle" onClick={() => updateIndicador(j)} icon={<SettingOutlined />} />
                                                <Popconfirm title='¿Seguro que quieres eliminar este Indicador?' onConfirm={() => eliminar(j.id)}><Button type="primary" htmlType="button" shape="circle" style={{marginLeft:30}} icon={<DeleteOutlined />}/></Popconfirm>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: ISMOBILE ? 'column' : 'row', marginTop: 10 }}>
                                            <Typography.Paragraph><b>Fecha inicio:</b> {j?.fecha_inicio}</Typography.Paragraph>
                                            <Typography.Paragraph><b>Valor actual</b> {j?.valor_actual}</Typography.Paragraph>
                                            <Typography.Paragraph><b>Fecha final</b> {j?.fecha_fin}</Typography.Paragraph>
                                            <Typography.Paragraph><b>Valor meta</b> {j?.valor_meta}</Typography.Paragraph>
                                        </div>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button type="primary" onClick={() => onOpenReporte(j?.id)} icon={<PlusOutlined />}>Añadir dato</Button>
                                        </div>
                                        <br></br>
                                        <TablaCollapse id_ind={j?.id} />
                                    </>
                                }]}
                            />
                        ))
                    }
                </Col>
            </>
        </Row>
        <CrearRegistro doc={doc} onCancel={onCancel} cont={2} regis={registro} update={update} idSub={idSub} />
        <AddDatos docReporte={docReporte} onCancelReporte={onCancelReporte} id_ind={idInd} />
    </div>
}

export default Subregistros



