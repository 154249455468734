
import ProtedPage from "./components/ProtedPage"
import Bienvenida from "./Pages/Bienvenida"
import Diagnostico from "./Pages/Diagnostico"
import Resultado from "./Pages/Diagnostico/Resultado"
import Directorio from "./Pages/Directorio"
import Home from "./Pages/Home"
import Subregistros from "./Pages/Subregistros"
import VentanaSubRegistros from "./Pages/Subregistros/VentaSubRegistros"

import "./App.css"
import HistorialReporte from "./Pages/HistorialReporte"
import { ConfigProvider } from "antd"

const { Routes, Route } = require("react-router-dom")

const App = () => {

    return (
        <ConfigProvider
            theme={{
                token: {
                    // Seed Token
                    colorPrimary: '#64B02A',
                    borderRadius: 6,
                    // Alias Token
                    colorBgContainer: '#f6ffed',
                },
                components:{
                    Header:{
                        colorPrimary:'#FFFFFF',
                    },
                    Table:{
                        colorBgContainer:'#FFFFFF',
                        colorBgBase:'#fff'
                    },
                    Collapse:{
                        colorBgContainer:'#FFFFFF',
                        colorBgBase:'#fff'
                    },
                    Card:{
                        colorBgContainer:'#FFFFFF',
                        colorBgBase:'#fff'
                    }
                }
            }}
        >
            <Routes>
                <Route path="/" element={<ProtedPage />}>
                    <Route path="/home" element={<Home />} />
                    <Route path="/diagnostico" element={<Diagnostico />} />
                    <Route path="/resultado/sum/:sum" element={<Resultado />} />
                    <Route path="/subregistros/registro/:id/name/:name" element={<VentanaSubRegistros />} />
                    <Route path="/subregistro/:id" element={<Subregistros />} />
                    <Route path="/directorio" element={<Directorio />} />
                    <Route path="/historial-reporte" element={<HistorialReporte />} />
                </Route>
                <Route path="/bienvenida" element={<Bienvenida />} />
            </Routes>
        </ConfigProvider>

    )
}

export default App