import { Col, Form, Input, Modal, notification, Row, Select } from "antd"
import { useEffect, useState } from "react"
import API from "../../../../API"
import PersonaJuridica from "./TipoPersona/PersonaJuridica"
import PersonaNatural from "./TipoPersona/PersonaNatural"
import PPS from "./TipoPersona/PPS"


const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const Registro = ({ docRegistro, onCancelRegistro }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const tipo_persona = Form.useWatch('persona', form)
    const isPps = Form.useWatch('pps', form)
    const [base64, setBase64] = useState('')
    const [pais,setPais] = useState(0)
    const [Dataciudad,setDataCiudad] = useState(0)

    const onOk = () => {
        form.submit()
    }

    const persona = async(id)=>{
        try{
            const {data} = await API.get(`api/user/usuario/user-detail`)
            form.setFieldsValue({
            nombre: data?.user?.first_name,
            apellido: data?.user?.last_name,
            email: data?.user?.email,
            persona: data?.user?.juridico ? 'juridica' : 'natural',
            identificacion: data?.persona?.fk_tipo_identificacion,
            numero_documento: data?.persona?.numero_documento,
            numero_telefono: data?.persona?.numero_telefono,
            sector: data?.persona?.sector,
            nombre_empresa: data?.persona?.nombre_empresa,
            numero_empleados: data?.persona?.numero_empleados,
            pais: data?.persona?.pais,
            sede:data?.persona?.sede,
            ciudad:data?.persona?.ciudad,
            direccion_empresa: data?.persona?.direccion_empresa,
            pagina_web: data?.persona?.pagina_web,
            actividad: data?.persona?.actividad,
            pps: data?.persona?.pps,
            oficio:data?.persona?.oficio,
            descripcion:data?.persona?.descripcion,
            registros:data?.persona?.fk_tipo_registros,
            url_video_1: data?.persona?.url_video_1,
            url_video_2: data?.persona?.url_video_2,
            url_video_3: data?.persona?.url_video_3,
        })
        setPais(docRegistro?.persona?.pais)
        setDataCiudad(docRegistro?.persona?.ciudad)
        }catch(error){
            console.log(error)
        }
        
    }

    const guardar = async (payload) => {
        try {
            const method = docRegistro.user?.id  ? 'put' : 'post'
            // const {data} = await API.post('api/user/usuario/registro/',{payload:payload})
            const url = docRegistro.user?.id ? `api/user/usuario/${docRegistro?.user?.id}/actualizar/` : 'api/user/usuario/registro/'
            const { data } = await API({
                url, method, data: {payload}
            })

            notification.open({
                type: 'success',
                message: docRegistro?.user?.id ? 'Actualizado con exito' : 'Usuario Registrado con exito'
            })
            onCancelRegistro()
        } catch (error) {
            console.log(error)
            notification.open({
                type: 'error',
                message: error?.response?.data?.error
            })
        }
    }

    const handleChange = (info) => {
        console.log(info)
        getBase64(info.file.originFileObj, (url) => {
            // console.log(url)
            setBase64(url)
            form.setFieldsValue({ logo: url })
        });
    };

    useEffect(() => {
        if (docRegistro) {
            console.log(docRegistro)
            persona(docRegistro?.id)
            
        }else{
            form.resetFields()
        }
    }, [docRegistro])


    const required = { required: true, message: 'Complete este campo' }

    return <Modal maskClosable={false} title='Formulario de Registro' onOk={onOk} okText='Guardar' closable={!loading} open={docRegistro} onCancel={onCancelRegistro} cancelButtonProps={{ style: { display: 'none' } }} width={500} style={{ width: 800 }}>
        <Form layout="vertical" onFinish={guardar} form={form}>
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item name='nombre' label='Nombre' rules={[required]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='apellido' label='Apellido' rules={[required]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='email' label='Correo Electrónico' rules={[required]}>
                        <Input />
                    </Form.Item>
                </Col>
                    <Col span={12}>
                    <Form.Item name='password' label='Password' rules={docRegistro?.user?.id ? '' : [required]}>
                        <Input.Password/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='password2' label='Password2' rules={docRegistro?.user?.id ? '' : [required]}>
                        <Input.Password/>
                    </Form.Item>
                </Col> 
                <Col span={24}>
                    <Form.Item name='persona' label='Tipo Persona' rules={[required]}>
                        <Select
                            placeholder='Tipo de Persona'
                            options={[
                                { value: 'juridica', label: 'Persona Jurídica' },
                                { value: 'natural', label: 'Persona Natural' }
                            ]}
                            disabled={docRegistro?.user?.id ? true : false}
                        />
                    </Form.Item>
                </Col>
                {tipo_persona === 'juridica' && <PersonaJuridica handleChange={handleChange} pais={pais}  Dataciudad={Dataciudad} perfil={true}/>}
                {tipo_persona === 'natural' && <PersonaNatural handleChange={handleChange} pais={pais} Dataciudad={Dataciudad} perfil={true}/>}
                <Col span={24}>
                    <Form.Item name='pps' label='Es PPSS' rules={[required]}>
                        <Select
                            placeholder='Es PPS'
                            options={[
                                { value: true, label: 'Si' },
                                { value: false, label: 'No' }
                            ]}

                        />
                    </Form.Item>
                </Col>
                {isPps === true && <PPS />}
            </Row>
        </Form>
    </Modal>
}

export default Registro