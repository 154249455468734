import { Col, Form, Input, Typography } from "antd"


const NuevaPassword = ()=>{
    const required = { required: true, message: 'Complete este campo' }
    return<>
        <Typography.Title level={5}>Ingrese su nueva contraseña</Typography.Title>
        <Col span={24}>
            <Form.Item name='password' label='Contraseña' rules={[required]}>
                <Input.Password/>
            </Form.Item>
        </Col>
        <Col span={24}>
            <Form.Item name='password2' label='Confirmar Contraseña' rules={[required]}>
                <Input.Password/>
            </Form.Item>
        </Col>
    </>
}

export default NuevaPassword