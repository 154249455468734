export const ISMOBILE = window.matchMedia('(max-width: 768px)').matches;
export const SEMANA = 'Semana'
export const DIA = 'Dia'
export const MES = 'Mes'

export const COLOR = '#F7F7F7'

export const comparadorFecha = (a, b) => {
    var fechaA = new Date(a.fecha_reporte);
    var fechaB = new Date(b.fecha_reporte);

    // Compara las fechas
    return fechaA - fechaB;
}