import { UploadOutlined } from "@ant-design/icons"
import { Button, Col, Form, Input, InputNumber, Row, Select, Upload } from "antd"
import { useEffect, useState } from "react"
import useSWR from 'swr'
import API from '../../../../../../API'


// const getBase64 = (img, callback) => {
//     const reader = new FileReader();
//     reader.addEventListener('load', () => callback(reader.result));
//     reader.readAsDataURL(img);
// };
// const beforeUpload = (file) => {
//     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
//     if (!isJpgOrPng) {
//         message.error('Favor subir png o jpg');
//     }
//     const isLt2M = file.size / 1024 / 1024 < 2;
//     if (!isLt2M) {
//         message.error('Image must smaller than 2MB!');
//     }
//     return isJpgOrPng && isLt2M;
// };

const PersonaJuridica = ({ handleChange, pais = 0, Dataciudad = 0, perfil = false }) => {
    const [ciudad, setCiudad] = useState({})
    // const [form] = Form.useForm()
    const fetcher = (url) => {
        return API(url).then(d => d?.data)
    }

    const paises = useSWR('api/configuracion/paises/', fetcher)
    const identificacion = useSWR('api/configuracion/identificacion/', fetcher)
    const registros = useSWR('api/configuracion/tipo_registro/', fetcher)

    const Ciudades = async (fk_pais) => {
        try {
            const { data } = await API.get(`api/configuracion/ciudad/`, { params: { fk_pais: fk_pais } })
            setCiudad({ ciudades: data })
        } catch (error) {
            console.log(error)
        }
    }

    const buscarCiudad = (dato) => {
        if (perfil) {
            console.log('ingfresa en el if', '----', dato)
            Ciudades(dato)
        } else {
            console.log('en el else')
            setCiudad(...paises?.data?.filter(e => e?.id === dato))
        }
    }

    // const handleChange = (info) => {
    //     getBase64(info.file.originFileObj, (url) => {
    //         // subir(url)
    //         form.setFieldValue({
    //             logo:url
    //         })
    //     });
    // };

    const required = { required: true, message: 'Complete este campo' }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        console.log(pais)
        if (pais > 0) {
            buscarCiudad(pais)
        }
    }, [pais])

    return <div style={{ width: '100%' }}>
        <Row gutter={32}>
            <Col span={12}>
                <Form.Item name='identificacion' label='Identificacion' rules={[required]}>
                    <Select
                        placeholder='Identificación'
                        options={identificacion?.data?.map(e => ({ value: e?.id, label: e?.identificacion }))}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='numero_documento' label='Num. Documento' rules={[required]}>
                    <InputNumber style={{ width: 210 }} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='numero_telefono' label='Num. Teléfono' rules={[required]}>
                    <InputNumber style={{ width: 130 }} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='sector' label='Sector económico' rules={[required]}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='nombre_empresa' label='Nombre empresa' rules={[required]}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='numero_empleados' label='Num. Empleados' rules={[required]}>
                    <Select
                        placeholder='Empleados'
                        options={[
                            { value: '1-10', label: '1-10' },
                            { value: '11-50', label: '11-50' },
                            { value: '51-500', label: '51-500' },
                            { value: '501 o mas', label: '501 o mas' }
                        ]}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='pais' label='País' rules={[required]}>
                    <Select
                        placeholder='País'
                        options={paises?.data?.map(e => ({ value: e?.id, label: e?.paises }))}
                        onChange={buscarCiudad}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='ciudad' label='Ciudad' rules={[required]}>
                    <Select
                        placeholder='Ciudad'
                        options={ciudad?.ciudades?.map(e => ({ value: e?.id, label: e?.ciudad }))}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='direccion_empresa' label='Dirección empresa' rules={[required]}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='pagina_web' label='Pagina web'>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='logo' label='Logo'>
                    <Upload
                        maxCount={1}
                        // beforeUpload={beforeUpload}
                        onChange={(info) => handleChange(info)}
                    >
                        <Button icon={<UploadOutlined />}>Cargar logo</Button>
                    </Upload>
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item name='registros' label='Registros de preferencias' rules={[required]}>
                    <Select
                        mode="multiple"
                        placeholder='Registros'
                        options={registros?.data?.map(e => ({ value: e?.id, label: e?.tipo }))}
                        filterOption={filterOption}
                    />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item label='descripcion' name='descripcion' rules={[required]}>
                    <Input.TextArea maxLength={150} showCount />
                </Form.Item>
            </Col>
        </Row>
    </div>
}

export default PersonaJuridica