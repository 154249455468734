import { Button, Card, Col, Progress, Row, Typography } from "antd"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import API from "../../API"
import { COLOR, ISMOBILE } from "../../constantes"
import './style_cuestionario.css'

const Diagnostico = () => {
    const navigate = useNavigate()
    const [preguntas, setPreguntas] = useState([])
    const [percent, setPrecent] = useState(0)
    const [inicio, setInicio] = useState(0)
    const [fin, setFin] = useState(4)
    const [cantidad, setCantidad] = useState(0)
    const [mostrar, setMostrar] = useState(false)
    const [array, setArray] = useState([])

    const listaPreguntas = async () => {
        try {
            const { data } = await API.get('api/cuestionario/preguntas/')
            setPreguntas(data)
            setCantidad(100 / (data?.length / 4))
        } catch (error) {
            console.log(error)
        }
    }

    const continuar = () => {
        setInicio(fin)
        setFin(fin + 4)
        setPrecent(percent + cantidad)
        if ((percent + cantidad) > 99.9) {
            setMostrar(true)
        }
    }

    const volver = () => {
        setInicio(inicio - 4)
        setFin(fin - 4)
        setPrecent(percent - cantidad)
        setMostrar(false)
    }

    const resultado = ()=>{
        const filtro = array.filter(e=>e?.tipo === 'si')
        const suma = filtro.reduce((a,b)=>a+b.puntaje,0)
        return suma
    }

    const finalizar = async () => {
        try {
            // const { data } = await API.post('api/cuestionario/preguntas/diagnostico/', { data: array })
            const resul = resultado()
            navigate(`/resultado/sum/${resul}`)
        } catch (error) {
            console.log(error)
        }
    }

    const guardar = (bool, i, id,puntaje) => {
        console.log(bool)
        const existe = array?.findIndex(e => e?.id === id)
        if (existe === -1) {
            setArray([...array, { tipo: bool, id: id,puntaje:puntaje }])
        } else {

            const nuevo = [...array]
            nuevo[existe] = {
                ...nuevo[existe],
                tipo: bool,
                id: id,
                puntaje:puntaje
            }
            setArray(nuevo)
        }
    }

    useEffect(() => {
        listaPreguntas()
    }, [])

    useEffect(() => {
        if (cantidad) {
            setPrecent(cantidad)
        }
        // if(cantidad === 100){
        //     setMostrar(true)
        // }
    }, [cantidad])

    const twoColors = {
        '0%': '#EFF8E9',
        '100%': '#64B02A',
      };

    return <div style={{ padding: 32, backgroundColor:COLOR }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
            <Typography.Title level={2}>Diagnostica de Sostenibilidad Ambiental</Typography.Title>
            <Typography.Paragraph>Responde algunas preguntas sobre la sostenibilidad en tu empresa</Typography.Paragraph>
        </div>
        <br />
        <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Progress percent={percent} showInfo={false} strokeColor={twoColors}/>
            <Row
                gutter={[8, 32]}
                style={{
                    marginLeft: ISMOBILE === false ? '10%' : '',
                    marginTop: '5%'
                }}
            >
                {
                    preguntas?.slice(inicio, fin)?.map((e, i) => (
                        <>
                            <Col span={12}>
                                <Typography.Text>{e?.pregunta}</Typography.Text>
                            </Col>
                            <Col span={12}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Button disabled={array?.filter(j=>j?.id === e?.id)[0]?.tipo === 'si' ? true : false} onClick={() => guardar('si', i, e?.id,e?.puntaje)} className="btn-diagnostico">SI</Button>

                                    <Button disabled={array?.filter(j=>j?.id === e?.id)[0]?.tipo === 'no' ? true : false} onClick={() => guardar('no', i, e?.id,0)} className="btn-diagnostico">NO</Button>
                                </div>
                            </Col>
                        </>
                    ))
                }
            </Row>
            <div style={{ display: 'flex', justifyContent: inicio > 0 ? 'space-between' : 'end', width: '100%', marginTop: 20 }}>
                {inicio > 0 ? <Button onClick={volver}>Volver</Button> : ''}
                {mostrar === false && <Button type="primary" onClick={continuar}>Continuar</Button>}
                {mostrar === true && <Button onClick={finalizar}>Finalizar</Button>}
            </div>
        </Card>
    </div>
}

export default Diagnostico