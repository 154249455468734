import { FilePdfOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Col, Collapse, DatePicker, Form, Row, Select,Table } from "antd"
import dayjs from "dayjs"

import { useEffect, useState } from "react"
import API from '../../API'
import ModalHistoria from "./components/ModalHistoria"
import useSWR from 'swr'
import { COLOR, ISMOBILE } from "../../constantes"


const HistorialReporte = () => {
    const [dato, setDato] = useState([])
    const [doc, setDoc] = useState(null)
    const [arr,setArr] = useState([])
    const [form] = Form.useForm()
    const [fitros, setFiltros] = useState(null)




    const fetcher = (url) => {
        return API(url).then(d => d?.data)
    }

    const sub_reg = useSWR('api/registro/subregistro/', fetcher)
    const registro = useSWR('api/registro/registro/', fetcher)

    const listaReoprte = async () => {
        try {
            const { data } = await API.get('api/reportes/reporte/')
            setDato(data)
        } catch (error) {
            console.log(error)
        }
    }

    const descargar = (doc) => {
        let arr = doc?.fk_sub_registro?.Datos?.filter(e => dayjs(e?.fecha_reporte).isBefore(dayjs(dato?.sys_fechaCreacion)))
      
        setArr(arr)
        
        setDoc([doc?.fk_sub_registro])

        setFiltros({ idInd: doc?.fk_indicador })

    }

    const onCancel = () => {
        setDoc(null)
    }

    const filtro = async (payload) => {
        try {
            const { data } = await API.get('api/reportes/reporte/', {
                params: {
                    fechas: payload?.rango,
                    id_sub: payload.id,
                    id_reg: payload.id_reg
                }
            })
            setDato(data)
        } catch (error) {
            console.log(error)
        }
    }

    const limpiar = () => {
        form.resetFields()
        listaReoprte()
    }

    // const generarPdf = async (docs) => {
    //     const contenido = imprimirRef.current
    //     const rect = contenido.getBoundingClientRect();
    //     const width = rect.width;
    //     const height = rect.height;

    //     const canvas = await html2canvas(contenido, {
    //         width: width,
    //         height: height
    //     })

    //     const doc = new jsPDF()
    //     doc.addImage(canvas.toDataURL("image/png"), "PNG", 15, 15, canvas.width * 0.75, canvas.height * 0.75);
    //     doc.save("pdf.pdf");
    // }
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    const items = [
        {
            key: '1',
            label: 'Filtros',
            children: <>
                <Form layout="vertical" form={form} onFinish={filtro}>
                    <Row gutter={32}>
                        <Col span={ISMOBILE ? 24 : 8}>
                            <Form.Item name='rango' label='Rango de fechas'>
                                <DatePicker.RangePicker />
                            </Form.Item>
                        </Col>
                        <Col span={ISMOBILE ? 24 : 8}>
                            <Form.Item name='id' label='Subregistros'>
                                <Select
                                    showSearch
                                    filterOption={filterOption}
                                    placeholder='Subregistros'
                                    options={sub_reg?.data?.map(e => ({ value: e?.id, label: e?.sub_registro }))}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={ISMOBILE ? 24 : 8}>
                            <Form.Item name='id_reg' label='Registros'>
                                <Select
                                    showSearch
                                    filterOption={filterOption}
                                    placeholder='Registros'
                                    options={registro?.data?.map(e => ({ value: e?.id, label: e?.otro === null ? e?.fk_tipo?.tipo : e?.otro }))}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={ISMOBILE ? 24 : 8}>
                            <Form.Item noStyle>
                                <Button htmlType="submit" type="primary">Filtrar</Button>
                                <Button onClick={limpiar} style={{ marginLeft: 20 }} htmlType="button">Limpiar Filtros</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        }
    ]

    useEffect(() => {
        listaReoprte()
    }, [])



    return <div style={{ backgroundColor: COLOR, padding: 32 }}>
        <Breadcrumb
            items={[
                {
                    title: 'Historial Reporte'
                }
            ]}
        />

        <ModalHistoria doc={doc} onCancel={onCancel} filtros={fitros} arr={arr}/>

        <br /><br />
        <Collapse items={items} />
        <br /><br />
        <Table
            pagination={false}
            bordered={false}
            rowKey='id'
            dataSource={dato}
        >
            <Table.Column title='Fecha de Creación' dataIndex='sys_fechaCreacion' render={e => dayjs(e).format('DD-MM-YYYY')} />
            <Table.Column title='Registro' dataIndex={'fk_sub_registro'} render={e => e?.fk_registro?.otro === null ? e?.fk_registro?.fk_tipo?.tipo : e?.fk_registro?.otro} />
            <Table.Column title='Sub Registro' render={doc => doc?.todos === false ? doc?.fk_sub_registro?.sub_registro : 'Todos'} />
            <Table.Column title='Descargar' render={doc => {
                return <>
                    <Button type="primary" shape="circle" onClick={() => descargar(doc)} icon={<FilePdfOutlined />} />
                </>
            }} />
        </Table>
    </div>
}

export default HistorialReporte