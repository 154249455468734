import { Button, Col, Form, Input, Modal, notification, Row, Typography } from "antd"
import { useState } from "react"
import API from "../../API"
import useAuth from "../../hooks/Auth"
import './style_login.css'
import login_img from "../../assets/imagenes/login.png"

const Login = ({ doc, onCancel,onRegistro,onCuenta }) => {
    const { login } = useAuth()
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    // const onOk = () => {
    //     form.submit()
    // }

    const acceder = async()=>{
        try{
            setLoading(true)
            const datos = form.getFieldsValue()
            const {data} = await API.post('api/user/usuario/login/',{email:datos.email,password:datos.password})
            login(data?.data?.access_token,data?.data?.num_reg)
            window.sessionStorage.setItem('pathname','/home')
        }catch(error){
            notification.open({
                type: 'error',
                message: error.response?.data?.error
            })
        }finally{
            setLoading(false)
        }
    }

    return <Modal maskClosable={false}  closable={!loading} open={doc} onCancel={onCancel} okButtonProps={{style:{display:'none'}}} cancelButtonProps={{ style: { display: 'none' } }} width={500} style={{ width: 800 }}>
        <Row>
            <Col span={12}>
                <img id="img" src={login_img}/>
            </Col>
            <Col span={12}>
                <Typography.Title level={5} style={{textAlign:'center'}}>Inicio de Sesión</Typography.Title>
                <Form form={form} disabled={loading} layout="vertical" >
                    <Form.Item name='email'>
                        <Input placeholder="Correo Electronico" required/>
                    </Form.Item>
                    <Form.Item name='password'>
                        <Input.Password placeholder="Contraseña" required/>
                    </Form.Item>
                    <Typography.Paragraph style={{cursor:'pointer'}} onClick={()=>onCuenta()} id="text-olvidar">Olvide mi contraseña</Typography.Paragraph>
                </Form>
                <Form.Item style={{display:'flex',justifyContent:'center'}}>
                    <Button type="primary" onClick={acceder} loading={loading}>Iniciar Sesión</Button>
                </Form.Item>
                <Typography.Paragraph style={{cursor:'pointer'}} id="text-cuenta" onClick={()=>onRegistro()}>¿No tienes una cuenta? regístrate aquí</Typography.Paragraph>
            </Col>

        </Row>

    </Modal>
}

export default Login