import { createContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../../API";
export const AuthContext = createContext();

const AuthProvider=({children})=>{
    const [token,setToken] = useState(localStorage.getItem("token") || '')
    const [user, setUser] = useState(null);
    const [cantidadRegistros,setCantidadRegistros] = useState(0)
    const [valid, setValid] = useState(null);
    const [loading, setLoading] = useState(true);
    const {pathname} = useLocation()

    const navigate = useNavigate();

    // function parseJwt (token) {
    //   try{
    //     var base64Url = token.split('.')[1];
    //     var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    //     var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    //         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    //     }).join(''));
    
    //     return JSON.parse(jsonPayload);
    //   }catch(error){
    //     return null
    //   }
    // }

    const login = async(data,num_reg) => {
        console.log('----num',num_reg)
        localStorage.setItem("token",data)
        setToken(data);
        setCantidadRegistros(parseInt(num_reg))
    };

    const logout = () => {
      console.log("%cSalir","color:red")
      window.sessionStorage.removeItem("pathname")
      localStorage.removeItem("token")
      // localStorage.setItem('theme','false')
      setToken('');
      setUser(null)
    };

    const validateSession=async ()=>{
      try{
        setLoading(true)
        if(token){
          const {data} = await API('api/user/usuario/user-detail/')
          setUser(data)
          setValid(true)
        }
      }catch(error){
        console.log(error)
        setToken('')
      }finally{
        setLoading(false)
      }
    }

    

    useEffect(()=>{
      console.log('token-----',token)
      if(token && token!=''){
        validateSession()
      }else{
        console.log("No hay token")
        setUser(null)
        setValid(false)
        setLoading(false)
      }
    },[token])

    useEffect(()=>{
      if(valid){
        let redirect='/home'
        let prev_path=window.sessionStorage.getItem("pathname")
        console.log(prev_path)
        redirect = prev_path || redirect
        console.log("%cir a "+redirect,"color:blue")
        navigate(redirect,{replace:true});
      }else if(valid===false){
        navigate("/bienvenida", { replace: true });
      }
    },[valid])


    useEffect(()=>{
      if(pathname!='/bienvenida'){
        window.sessionStorage.setItem("pathname",pathname)
      }
     
    },[pathname])
    

    const value = useMemo(
        () => ({
          token,
          user,
          cantidadRegistros,
          login,
          logout
        }),
        [user,token,cantidadRegistros]
      );

      if(loading){
        return <div>Cargando...</div>
      }

    

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider