import { Col, Form, Input, Typography } from "antd"


const Codigo = ()=>{
    const required = { required: true, message: 'Complete este campo' }
    return<>
        <Typography.Title level={5}>Ingrese codigo enviado a su correo electrónico</Typography.Title>
        <Col span={24}>
            <Form.Item name='codigo' label='Código' rules={[required]}>
                <Input/>
            </Form.Item>
        </Col>
    </>
}

export default Codigo