import { DeleteOutlined, EditOutlined, LinkOutlined } from "@ant-design/icons"
import { Button, Popconfirm, Space, Table, Typography } from "antd"
import { useEffect, useState } from "react"
import API from "../../../../API"
import useUpdateAddatos from "../../../../hooks/UpdateAddDatos"
import AddDatos from "../AddDatos"



const TablaCollapse = ({ id_ind}) => {
    const [datos,setDatos] = useState([])
    const [doc, setDoc] = useState(null)
    const [idInd, setIdInd] = useState(0)

    const {updateAdd} = useUpdateAddatos()

    const onCancelReporte = () => {

        setDoc(null)
    }

    const listDatos = async ()=>{
        try {
            console.log('si se llama')
            const {data} = await API.get('api/registro/datos/',{ params:{id_ind:id_ind} })
            setDatos(data)
        } catch (error) {
            console.log(error)
        }
    }

  

    const eliminar = async (id)=>{
        try{
            const {data} = await API.delete(`api/registro/datos/${id}/`)
            // onCancelReporte()
            listDatos()
        }catch(error){
            console.log(error)
        }
    }

    useEffect(()=>{
        listDatos()
    },[])

    useEffect(()=>{
        console.log(doc)
        if(doc === null){
            console.log('llega al useefect')
            listDatos()
        }
    },[doc])

    useEffect(()=>{
        if(updateAdd){
            console.log('updateAdd','-----',updateAdd)
            listDatos()
        }
    },[updateAdd])

    return <div>
        <Table
            pagination={false}
            bordered={false}
            scroll={{
                x: 800,
                y: 300,
            }}
            rowKey='id'
            dataSource={datos}
        >
            <Table.Column title='Fecha Reporte' dataIndex='fecha_reporte' width={140}/>
            <Table.Column title='Valor' dataIndex='valor' width={130}/>
            <Table.Column title='Soporte' dataIndex='soporte' width={130}  render={(e)=>(e != null ? <Typography.Link href={e} target='_blank'><LinkOutlined />Ver Soporte</Typography.Link>: <Typography.Text>No hay soporte</Typography.Text>)}/>
            <Table.Column title='Acciones' width={100} render={(doc)=>(
                <>
                    <Space>
                        <Button onClick={()=>setDoc(doc)} shape="circle" icon={<EditOutlined/>}/>
                        <Popconfirm title='¿Estas seguro que desea eliminar?' onConfirm={()=>eliminar(doc?.id)}><Button  shape="circle" icon={<DeleteOutlined/>}/></Popconfirm>
                    </Space>
                </>
            )}/>
        </Table>
        <AddDatos docReporte={doc} onCancelReporte={onCancelReporte} id_ind={idInd} />
    </div>
}

export default TablaCollapse