import { Col, Input, Row, Select, Typography } from "antd"
import { useEffect, useState } from "react"
import API from "../../API"
import { COLOR, ISMOBILE } from "../../constantes"
import Cards from "./Cards"



const Directorio = () => {
    const [datos, setDatos] = useState([])
    const [select, setSelect] = useState([])
    const [reg, setReg] = useState([])

  



    const listRegistros = async () => {
        try {
            const { data } = await API.get('api/configuracion/tipo_registro/')
            setReg(data)
        } catch (error) {
            console.log(error)
        }
    }

    const listDirectorio = async () => {
        try {
            const { data } = await API.get('api/user/juridico/')
            console.log(data)
            setDatos(data)
        } catch (error) {
            console.log(error)
        }
    }

    const busquedaInput = async (payload) => {
        console.log(payload.target.value)
        try {
            const { data } = await API.get('api/user/juridico/', { params: { texto: payload.target.value } })
            setDatos(data)
        } catch (error) {
            console.log(error)
        }
    }

    const busquedaSelect = async (payload) => {
        console.log(payload)
        try {
            if (payload === 'todos') {
                const {data} = await API.get('api/user/juridico/')
                setDatos(data)
            } else {
                const { data } = await API.get('api/user/juridico/', { params: { fk_registro: payload } })
                setDatos(data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        listDirectorio()
        listRegistros()
    }, [])

    useEffect(() => {
        if (reg.length) {
            const temp = [...reg?.map(e => ({ value: e?.id, label: e?.tipo })), { value: 'todos', label: 'Todos' }]
            setSelect(temp)
        }
    }, [reg])

    return <div style={{ padding: ISMOBILE ? 6 : 32, backgroundColor: COLOR }}>
        <Row>
            <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
                <div>
                    <Typography.Title level={ISMOBILE ? 4 : 2}>Tus aliados de confianza</Typography.Title>
                </div>
                <div>
                    <Typography.Text style={{ textAlign: 'center' }}>Encuentra aquí los mejores proveedores según tu necesidad</Typography.Text>
                </div>
            </Col>
            <Col span={24}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                    marginTop: 30
                }}>
                <Input.Search style={{ width: ISMOBILE === false ? 380 : 300 }} size='large' placeholder="¿Que necesitas hoy?" onChange={busquedaInput} />
            </Col>
            <Col span={24}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                    marginTop: 30
                }}
            >
                <Select
                    size="large"
                    placeholder='Servicios'
                    style={{ width: ISMOBILE === false ? 380 : 300 }}
                    options={select}
                    onChange={busquedaSelect}
                />
            </Col>
            {/* <Col span={24} style={{ display: 'flex',justifyContent:'center', alignItems: 'center', textAlign: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row',justifyContent:'space-between',width:'50%'}}>
                    <div>
                        <Button>hola</Button>
                    </div>
                    <div>
                        <Button>hola</Button>
                    </div>
                    <div>
                        <Button>hola</Button>
                    </div>
                </div>
            </Col> */}
        </Row>
        <div style={{ display: 'grid', gridTemplateColumns: ISMOBILE ? 'repeat(1,500px)' : 'repeat(5,500px)', gap: '16px 16px', marginTop: 50 }}>
            {
                datos?.map(e => (
                    <Cards data={e} />
                ))
            }
        </div>
    </div>
}

export default Directorio