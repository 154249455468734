import { PrinterFilled } from "@ant-design/icons"
import { Button, Card, Col, Row, Typography } from "antd"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import API from "../../../API"
import { COLOR, ISMOBILE } from "../../../constantes"
import useAuth from "../../../hooks/Auth"
import './style_resultado.css'


const Resultado = () => {
    const { sum } = useParams()
    const [data, setData] = useState({})
    const { cantidadRegistros } = useAuth()


    const resultado = async () => {
        try {
            const { data } = await API.post(`api/cuestionario/probabilidad/resultado/`, { resultado: sum })
            setData(data)
        } catch (error) {
            console.log(error)
        }
    }

    const imprimir = ()=>{
        let contenido = document.getElementById('div-resultado').innerHTML
        let contenido_original = document.body.innerHTML

        document.body.innerHTML = contenido
        window.print()
        document.body.innerHTML = contenido_original
    }

    useEffect(() => {
        resultado()
    }, [])

    console.log(cantidadRegistros,'----cantidad')

    return <div style={{ padding: 32, backgroundColor:COLOR }} id='div-resultado'>
        <div style={{display:'flex',justifyContent:'flex-end'}}>
            <Button onClick={imprimir} type="primary" icon={<PrinterFilled/>}>Imprimir</Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', flexDirection: 'column' }}>
            <Typography.Title level={2}>¡Listo!</Typography.Title>
            <Typography.Paragraph>De acuerdo a tus respuestas, obtuvimos el siguiente análisis </Typography.Paragraph>
        </div>
        <br />
        <Card>
            <Row gutter={-16}>
                <Col span={ISMOBILE ? 24 : 12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div id={ISMOBILE ? "promedio-cel" : 'promedio'} style={{ backgroundColor: data?.analisis?.color }}>
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center' }}>
                            <Typography.Text>Puntaje</Typography.Text>
                            <div>
                                <Typography.Title>{sum}</Typography.Title>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={ISMOBILE ? 24 : 12}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography.Title level={ISMOBILE ? 4 : 2}>{data?.modelo}</Typography.Title>
                        <Typography.Text>{data?.analisis?.interpretacion}</Typography.Text>
                    </div>
                </Col>
            </Row>
        </Card>
        <Row>
            <Col span={24} style={{ display: 'flex', margin: 30 }}>
                <Typography.Title level={ISMOBILE ? 4 : 2}>Análisis Estocástico sobre la sostenibilidad</Typography.Title>
            </Col>
            <Col span={24}>
                <Typography.Title level={3} style={{ margin: 30 }}>Pérdidas estimadas:</Typography.Title>
                <Typography.Paragraph style={{ margin: 30, textAlign: 'justify' }}>{data?.analisis?.perdidas}</Typography.Paragraph>
            </Col>
            <Col span={24}>
                <Typography.Title level={3} style={{ margin: 30 }}>Ganancias estimadas</Typography.Title>
                <Typography.Paragraph style={{ margin: 30, textAlign: 'justify' }}>{data?.analisis?.ganancias}</Typography.Paragraph>
            </Col>
            <Col>
                {
                    data?.probabilidad?.map(e => (
                        <>
                            <li style={{ margin: 20, marginLeft: 40 }}>{e}</li>
                        </>
                    ))
                }
            </Col>
        </Row>
        {/* <Row style={{ margin: 30 }}>
            {
                cantidadRegistros == 0 ? <>
                    <Col span={24}>
                        <Typography.Title level={ISMOBILE ? 3 : 2} style={{ textAlign: 'center' }}>
                            Es hora de llevar el registro ambiental de tu empresa
                        </Typography.Title>
                    </Col>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginBottom: 20 }}>
                        <Typography.Text style={{ textAlign: 'center' }}>
                            Crea tu registro junto con sus indicadores y comienza a cargar Tus consumo y utiliza esa trazabilidad para tus certificaciones
                        </Typography.Text>
                    </Col>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                        <Button onClick={()=>navigate('/home')}>Crear mi Primer Registro</Button>
                    </Col>
                </> : null
            }
        </Row> */}
    </div>
}

export default Resultado