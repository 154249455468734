import { PrinterOutlined } from "@ant-design/icons"
import { Button, Modal } from "antd"
import { useRef, useState } from "react"
import ReactToPrint from "react-to-print"
import ImprimirReporte from "../../../Subregistros/VentaSubRegistros/components/ImprimirReporte"


const ModalHistoria = ({doc,onCancel,filtros,arr=null})=>{
    const orden_contenido = useRef(null)
    return <Modal maskClosable={false}  title='Reporte'  closable={!false} open={doc} onCancel={onCancel} okButtonProps={{style:{display:'none'}}} cancelButtonProps={{ style: { display: 'none' } }} width={800} style={{ width: 800 }}>
        <div>
            <ReactToPrint
                trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return <Button type="primary" icon={<PrinterOutlined />}>Imprimir</Button>;
                }}
                
                content={() => orden_contenido.current}
            />
            <div  ref={el => (orden_contenido.current = el)}>
                <ImprimirReporte datos={doc} print={filtros} arr={arr}/>
            </div>
        </div>
    </Modal>
}

export default ModalHistoria