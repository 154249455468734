import { Form, Modal, notification, Row } from "antd"
import { useEffect, useState } from "react"
import Codigo from "./Codigo"
import Correo from "./Correo"
import NuevaPassword from "./NuevaPassword"
import API from "../../../../API"



const RecuperarCuenta = ({ docCuenta, onCancelCuenta }) => {
    const [loading, setLoading] = useState()
    const [form] = Form.useForm()
    const [cont, setCont] = useState(0)
    const [codigo, setCodigo] = useState(null)

    const onOk = () => {
        form.submit()
    }

    const salir = () => {
        setCont(0)
        onCancelCuenta()
    }

    const recuperar = async (payload) => {
        console.log(payload)
        try {
            if (cont === 0) {
                setLoading(true)
                const { data } = await API.post('api/user/usuario/envio-token/', payload)
                notification.open({
                    type: 'info',
                    message: 'Código de Validación enviado a su correo electrónico'
                })
                setCont(cont + 1)
            } else if (cont === 1) {
                console.log(payload, '------', 'codigo envio')
                setLoading(true)
                await API.post('api/user/usuario/validacion-token/', payload)
                setCont(cont + 1)
                setCodigo(payload.codigo)
            } else {
                setLoading(true)
                await API.post('api/user/usuario/cambio-password/', { ...payload, codigo: codigo })
                notification.open({
                    type: 'success',
                    message: 'Contraseña Restablecida con éxito, Ya puede iniciar Sesión'
                })
                onCancelCuenta()
            }
        } catch (error) {
            console.log(error)
            notification.open({
                type: 'error',
                message: error?.response?.data?.error
            })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        form.resetFields()
    }, [])

    return <Modal maskClosable={false} title='Recuperar Cuenta' onOk={onOk} confirmLoading={loading} okText='Guardar' closable={!loading} open={docCuenta} onCancel={salir} cancelButtonProps={{ style: { display: 'none' } }} width={500} style={{ width: 800 }}>
        <Form form={form} layout='vertical' onFinish={recuperar}>
            <Row>
                {cont === 0 && <Correo />}
                {cont === 1 && <Codigo />}
                {cont === 2 && <NuevaPassword />}
            </Row>
        </Form>
    </Modal>
}
export default RecuperarCuenta